<template>
    <div class="LeftTop">
        <div class="total__chart">
            <LineTitle title="汇总" />
            <ECharts :option="totalOption" />
        </div>
        <div class="rank__chart">
            <LineTitle title="实验人数排行Top5" />
            <div class="column" v-for="(item, index) in rankList" :key="index">
                <div class="label">{{ item.ProjectName }}</div>
                <div class="bars">
                    <!-- <div class="inner"></div> -->
                    <div class="outer" :style="{ width: `${(Number(item.PersonTime) / num) * 100}%` }"
                        :class="{ 'top-3 ': index < 3 }">
                    </div>
                </div>
                <div class="num">{{ item.PersonTime }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import ECharts from "@/components/ECharts/index.vue";
import LineTitle from '@/components/LineTitle'
import { getPeopleTotalChart } from "@/utils/genChartData";
import { GetUserStatisticByType, GetProjectRanking } from '@/api/index'

const totalOption = ref({})
const num = ref(0)
const rankList = ref([])
const init = async () => {
    const { ResData: UserStatisticData } = await GetUserStatisticByType()
    totalOption.value = getPeopleTotalChart(UserStatisticData)

    const { ResData: RankingData } = await GetProjectRanking()
    num.value = RankingData.reduce((pre, cur) => pre + Number(cur.PersonTime), 0)
    rankList.value = RankingData.slice(0, 5)
}
init()
</script>

<style lang="scss" scoped>
.LeftTop {
    width: 100%;
    height: 100%;

    .total__chart {
        height: 50%;
    }

    .rank__chart {
        margin-top: 40px;

        .column {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            flex: 0 0 auto;
            font-size: 12px;
            height: 37px;

            .label {
                width: 200px;
                color: #ccc;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .bars {
                position: relative;
                top: -6px;
                width: calc(100% - 90px);
                cursor: auto;

                .inner {
                    position: absolute;
                    background: rgba(151, 136, 136, 0.4);
                    width: 100%;
                    height: 12px;
                    border-radius: 8px;
                }

                .outer {
                    position: absolute;
                    background: linear-gradient(to left, #00c0fa, #2f95fa);
                    height: 12px;
                    border-radius: 8px;
                    transition: width 0.3s ease 0s;
                }

                .top-3 {
                    background: linear-gradient(to left, #ee901e, #f5ec45);
                }
            }

            .num {
                width: 45px;
                color: #ccc;
                text-align: right;
            }
        }
    }
}
</style>