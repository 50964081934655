import {
  createRouter,
  createWebHashHistory
} from "vue-router";
import HomeView from "@/views/Home/Home.vue";
import {
  getTokenApi
} from '@/api/index'
import {
  setToken
} from '@/utils/token'

import {
  getToken
} from '@/utils/token'
const routes = [{
  path: "/",
  name: "home",
  component: HomeView,
  // 有token才能进入
  beforeEnter: (to, from, next) => {
    const token = getToken()
    if (token) {
      next()
    } else {
      getTokenApi().then(({
        ResData
      }) => {
        setToken(ResData.AccessToken)
        next()
      })

    }
  }
}, ];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;