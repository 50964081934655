import axios from "axios";
import {
    getToken
} from "@/utils/token";
import {
    GetRandomString,
    sign
} from "@/utils/utils";
const apis = {
    production: "https://api.wehuatech.com/xnfz", // 生产环境
    development: "http://localhost:5001", //开发环境
};
const server = axios.create({
    baseURL: process.env.NODE_ENV === "development" ? apis.development : apis.production,
    timeout: 30000,
});
server.interceptors.request.use(
    (config) => {
        const token = getToken();
        if (token) {
            config.headers["Authorization"] = "Bearer " + token;
            const timeStamp = new Date().getTime().toString().substr(0, 10);
            const nonce = GetRandomString();
            config.headers["appId"] = "system";
            config.headers["nonce"] = nonce;
            config.headers["timeStamp"] = timeStamp;
            config.headers["signature"] = sign(config, nonce, timeStamp, "system");
        }
        return config;
    },
    (err) => {
        Promise.reject(err);
    }
);

server.interceptors.response.use(
    (res) => {
        // res 是所有相应的信息
        return Promise.resolve(res.data);
    },
    (err) => {
        Promise.reject(err);
    }
);

export default server;