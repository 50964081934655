<template>
    <div class="DataScreening">
        <ul>
            <li v-for="(item, index) in dataList" :key="index">
                <div class="label">{{ item.label }}</div>
                <div class="value">{{ item.value }}</div>
            </li>
        </ul>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
const props = defineProps({
    data: {
        type: Object,
        default: () => {
            return {}
        }
    }
})
const dataList = ref([
    {
        label: "项目数量",
        value: 0
    },
    {
        label: "实验人数",
        value: 0
    },
    {
        label: "实验次数",
        value: 0
    },

])
watch(() => props.data, (newValue, oldValue) => {
    dataList.value[0].value = newValue.TotalBrowse
    dataList.value[1].value = newValue.PersonTime
    dataList.value[2].value = newValue.Number
})
</script>

<style lang="scss" scoped>
.DataScreening {
    padding: 4px 0 10px;

    ul {
        list-style: none;
        display: flex;
        justify-content: space-around;
        text-align: center;

        .label {
            font-size: 16px;
            color: rgb(250, 250, 250);
            margin-bottom: 6px;
        }

        .value {
            font-size: 28px;
            color: #e8bb3f;
        }
    }
}
</style>