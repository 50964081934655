<template>
    <div class="Map">
        <LineTitle title="用户分布" />
        <div class="chart">
            <ECharts :option="option" />
        </div>
    </div>
</template>

<script setup>
import LineTitle from '@/components/LineTitle'
import ECharts from "@/components/ECharts/index.vue";
import { getChinaMap } from "@/utils/genChartData";

const randomData = () => {
    return Math.round(Math.random() * 1000);
}
const mapData = [
    { name: "上海", value: 0 },
    { name: "北京", value: 200 },
    { name: "天津", value: 100 },
    { name: "重庆", value: 30 },
    { name: "河北", value: 30 },
    { name: "河南", value: 30 },
    { name: "云南", value: 30 },
    { name: "辽宁", value: 30 },
    { name: "黑龙江", value: 30 },
    { name: "湖南", value: 30 },
    { name: "安徽", value: 30 },
    { name: "山东", value: 30 },
    { name: "新疆", value: 30 },
    { name: "江苏", value: 30 },
    { name: "浙江", value: 30 },
    { name: "江西", value: 30 },
    { name: "湖北", value: 30 },
    { name: "广西", value: 30 },
    { name: "甘肃", value: 30 },
    { name: "山西", value: 30 },
    { name: "内蒙古", value: 30 },
    { name: "陕西", value: 30 },
    { name: "吉林", value: 30 },
    { name: "福建", value: 30 },
    { name: "贵州", value: 30 },
    { name: "广东", value: 30 },
    { name: "青海", value: 30 },
    { name: "西藏", value: 30 },
    { name: "四川", value: 30 },
    { name: "宁夏", value: 30 },
    { name: "海南", value: 30 },
    { name: "台湾", value: 30 },
    { name: "香港", value: 30 },
    { name: "澳门", value: 30 }
]
const option = getChinaMap(mapData)

</script>

<style lang="scss" scoped>
.Map {
    width: 100%;
    height: 100%;

    .chart {
        width: 100%;
        height: 100%;
    }
}
</style>
