<template>
    <div class="Visit">
        <LineTitle title="访问统计" />
        <div class="FunnelChart">
            <ECharts :option="funnelOption" />
        </div>
        <div class="LineChart">
            <ECharts :option="lineOption" />
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import LineTitle from '@/components/LineTitle'
import ECharts from "@/components/ECharts/index.vue";
import { getVisitFunnelChart, getVisitLineChart } from "@/utils/genChartData";
import { GetUserStatisticByTime } from '@/api/index'
const funnelOption = ref({})
const lineOption = ref({})
const init = async () => {
    const { ResData: ByTimeData } = await GetUserStatisticByTime()
    const ilab = ByTimeData.filter(i => i.OpenIdType === 'wh.openid.ilab')
    const jnu = ByTimeData.filter(i => i.OpenIdType === 'wh.openid.jnu')
    const wx = ByTimeData.filter(i => i.OpenIdType === 'wh.openid.wx')
    const funnelData = [
        {
            name: '平台用户',
            value: jnu.reduce((pre, cur) => pre + Number(cur.Num), 0)
        },
        {
            name: 'iLab用户',
            value: ilab.reduce((pre, cur) => pre + Number(cur.Num), 0)
        },
        {
            name: '校外',
            value: wx.reduce((pre, cur) => pre + Number(cur.Num), 0)
        },
    ]
    funnelOption.value = getVisitFunnelChart(funnelData)


    const allTime = [...new Set(ByTimeData.map(i => i.Time))]
    const newList = []
    allTime.forEach(i => {
        const jnuData = jnu.find(item => i === item.Time) || {}
        const ilabData = ilab.find(item => i === item.Time) || {}
        const wxData = wx.find(item => i === item.Time) || {}
        newList.push({
            time: i,
            platform: Number(jnuData.Num),
            ilab: Number(ilabData.Num),
            school: Number(wxData.Num)
        })
    })
    lineOption.value = getVisitLineChart(newList)
}
init()
</script>

<style lang="scss" scoped>
.Visit {
    width: 100%;
    height: 100%;

    .FunnelChart {
        height: 38%;
    }

    .LineChart {
        height: 55%;
    }
}
</style>