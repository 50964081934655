import md5 from 'js-md5'
/**
 * 时间格式化
 */
export function moment(timestamps = null) {
  const d = timestamps ? new Date(timestamps) : new Date();
  const [year, month, day, hour, minute, second] = [
    d.getFullYear(),
    d.getMonth() + 1 >= 10 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
    d.getDate() >= 10 ? d.getDate() : `0${d.getDate()}`,
    d.getHours() >= 10 ? d.getHours() : `0${d.getHours()}`,
    d.getMinutes() >= 10 ? d.getMinutes() : `0${d.getMinutes()}`,
    d.getSeconds() >= 10 ? d.getSeconds() : `0${d.getSeconds()}`,
  ];

  return {
    format(patterns = "YYYY-MM-DD HH:mm:SS") {
      return patterns
        .replace(/YYYY/g, year)
        .replace(/MM/g, month)
        .replace(/DD/g, day)
        .replace(/HH/g, hour)
        .replace(/mm/g, minute)
        .replace(/SS/g, second);
    },
  };
}

/**
 * 获取一个32位随机字符串
 * @returns
 */
export const GetRandomString = () => {
  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789'
  var maxPos = chars.length
  var pwd = ''
  for (var i = 0; i < 32; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd
}

export const sign = (config, nonce, timestamp, appSecret) => {
  // 签名格式： timestamp + nonce + data(字典升序)+appSecret
  const ret = []
  if (config.params) {
    const pArray = []
    const data = config.params
    for (const it in data) {
      pArray.push(it)
    }
    // 字典升序
    const sArray = pArray.sort()
    for (const it of sArray) {
      let val = data[it]
      if (typeof val === 'object' && //
        (!(val instanceof Array) || (val.length > 0 && (typeof val[0] === 'object')))) {
        val = JSON.stringify(val)
      }
      ret.push(it + val)
    }
  } else {
    ret.push(JSON.stringify(config.data))
  }
  const signsrc = timestamp + nonce + ret.join('') + appSecret
  return md5(signsrc)
}