<template>
    <div class="RightTop">
        <div class="data__screening">
            <LineTitle title="数据总览" />
            <DataScreening :data="data"></DataScreening>
        </div>
        <div class="experimental__analysis">
            <LineTitle title="实验分析" />
            <div class="top__chart">
                <ECharts :option="option" />
            </div>
            <div class="bottom__box">
                <div class="left">
                    <ECharts :option="option2" />
                </div>
                <div class="right">
                    <ECharts :option="option3" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import LineTitle from '@/components/LineTitle'
import ECharts from "@/components/ECharts/index.vue";
import DataScreening from './components/DataScreening.vue'
import { GetProjectSummary, GetTrendDataByMonth, GetProjectSummaryByField, GetScoreSummary } from '@/api/index'
import { getAnalysisChart, getBottomLeftChart, getBottomRightChart } from "@/utils/genChartData";
const data = ref({})
const option = ref({})
const option2 = ref({})
const option3 = ref({})
const init = async () => {
    const { ResData } = await GetProjectSummary()
    data.value = ResData[0]
    const { ResData: TrendDataByMonth } = await GetTrendDataByMonth()
    option.value = getAnalysisChart(TrendDataByMonth)
    const { ResData: ProjectSummaryByField } = await GetProjectSummaryByField()
    option2.value = getBottomLeftChart(ProjectSummaryByField)
    const { ResData: ScoreSummaryData } = await GetScoreSummary()
    option3.value = getBottomRightChart(ScoreSummaryData)
}
init()
</script>

<style lang="scss" scoped>
.RightTop {
    width: 100%;
    height: 100%;

    .experimental__analysis {
        width: 100%;
        height: 100%;

        .top__chart {
            height: 40%
        }

        .bottom__box {
            display: flex;
            width: 100%;
            height: 100%;

            .left {
                flex: 1;
                height: 35%;
            }

            .right {
                flex: 1;
                height: 35%;

            }
        }
    }
}
</style>