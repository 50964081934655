import {
    createApp
} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import 'animate.css/animate.min.css'

import VueParticles from "vue-particles";
import "normalize.css";
createApp(App).use(store).use(router).use(VueParticles).mount("#app");