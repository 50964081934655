<template>
  <div :id="chartId" style="width: 100%; height: 100%"></div>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, onBeforeUnmount } from "vue";
import * as echarts from "echarts";
import '../../assets/china';
import 'echarts-gl';
import _ from "lodash";
const chartId = ref("chart" + Math.random());
// 父组件只需要传入echarts options样式即可
const props = defineProps({
  option: {
    type: Object,
    required: true,
  },
});

let chart;

const drawChart = () => {
  chart = echarts.init(document.getElementById(chartId.value));
  chart.setOption(props.option);
};

// 自动调整图表大小
const resize = () => {
  chart && chart.resize();
};
const throttleFunResize = _.throttle(resize, 500);
window.addEventListener("resize", throttleFunResize);

// 重置样式
const setOption = () => {
  chart.setOption(props.option);
};
const debounceSetOption = _.debounce(setOption, 500);
watch(
  () => props.option,
  (value, oldValue) => {
    debounceSetOption(value);
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  drawChart();
});
onBeforeUnmount(() => {
  window.removeEventListener("resize", throttleFunResize);
});
</script>

<style lang="scss" scoped>
</style>
