<template>
  <div class="home">
    <!-- 颗粒背景 -->
    <vue-particles class="bg" color="#a8a8a8" :particleOpacity="0.5" :particlesNumber="150" shapeType="circle"
      :particleSize="4" linesColor="#5db896" :linesWidth="1" :lineLinked="true" :lineOpacity="1" :linesDistance="60"
      :moveSpeed="0.3" :hoverEffect="true" hoverMode="grab" :clickEffect="false" clickMode="push" />
    <div class="header">
      <Header />
    </div>
    <div class="center-top">
      <!-- 地图 -->
      <Map />
    </div>
    <div class="right-bottom">
      <!-- 用户实时行为 -->
      <RealAct />
    </div>
    <div class="right-top">
      <RightTop />
    </div>
    <div class="center-bottom">
      <!-- 统计 -->
      <Statistics />
    </div>
    <div class="left-top">
      <LeftTop />
    </div>
    <div class="left-bottom">
      <!-- 访问 -->
      <Visit />
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";
import Header from "../Header/Header.vue";
import RealAct from "../RealAct/RealAct.vue";
import Map from "../Map/Map.vue";
import RightTop from "../RightTop/index.vue";
import LeftTop from "../LeftTop/index.vue";
import Statistics from "../Statistics/Statistics.vue";
import Visit from "../Visit/Visit.vue";
const route = useRoute();
document.title="数据看板"
// const { id } = route.params;
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  min-width: 1920px;
  min-height: 1080px;
  // min-width: 100vw;
  // min-height: 100vh;
  font-size: 14px;
  display: grid;
  grid-template-columns: repeat(9, 1fr); // 9 * 1fr //100%剩余空间
  grid-template-rows: 80px repeat(8, 1fr); // 8 * 1fr
  grid-gap: 5px; //设置行与列的间隙
  padding: 6px;
  z-index: 99;
  background: #283644;
  box-sizing: border-box;

  // 颗粒背景
  .bg {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
  }

  .center-bottom,
  .center-top,
  .left-bottom,
  .left-top,
  .right-top,
  .right-bottom {
    position: relative;
    background: rgba(40, 47, 56, 0.5);
    border-radius: 6px;
    padding: 10px;
  }

  .header {
    grid-area: 1 / 1 / span 1 / span 9;
  }

  .center-top {
    grid-area: 2 / 3 / span 5 / span 4;
  }

  .right-bottom {
    grid-area: 7 / 7 / span 3 / span 3;
  }

  .right-top {
    grid-area: 2 / 7 / span 5 / span 3;
  }

  .center-bottom {
    grid-area: 7 / 3 / span 3 / span 4;
  }

  .left-bottom {
    grid-area: 6 / 1 / span 4 / span 2;
  }

  .left-top {
    grid-area: 2 / 1 / span 4 / span 2;
  }
}
</style>
