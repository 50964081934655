<template>
    <div class="statistics">
        <div class="statistics__left">
            <LineTitle title="实验分类统计" />
            <div class="left__chart">
                <ECharts :option="left_option" />
            </div>
        </div>
        <div class="statistics__right">
            <LineTitle title="院系统计" />
            <div class="right__chart">
                <ECharts :option="right_option" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import LineTitle from '@/components/LineTitle'
import ECharts from "@/components/ECharts/index.vue";
import { getStatisticsChart } from "@/utils/genChartData";
import { GetProjectSummaryBySubject, GetProjectSummaryByFullName } from '@/api/index'
const left_option = ref({})
const right_option = ref({})
const init = async () => {
    const { ResData: BySubjectData } = await GetProjectSummaryBySubject()
    left_option.value = getStatisticsChart(BySubjectData)
    const { ResData: ByFullNameData } = await GetProjectSummaryByFullName()
    right_option.value = getStatisticsChart(ByFullNameData)

}
init()
</script>

<style lang="scss" scoped>
.statistics {
    width: 100%;
    height: 100%;
    display: flex;

    &__left {
        width: 50%;

        // display: flex;
        .left__chart {
            margin: auto auto;
            width: 80%;
            height: 80%;
        }
    }

    &__right {
        width: 50%;

        .right__chart {
            margin: auto auto;
            width: 80%;
            height: 80%;
        }
    }

}
</style>