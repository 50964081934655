import Cookies from 'js-cookie'

const TOKENSTORAGE = "bi_token"

export function getToken() {
    return sessionStorage.getItem(TOKENSTORAGE) || Cookies.get(TOKENSTORAGE)
}
export function setToken(token) {
    if (token) {
        sessionStorage.setItem(TOKENSTORAGE, token)
        Cookies.set(TOKENSTORAGE, token, 24 * 3)
    }
}
export function removeToken() {
    sessionStorage.removeItem(TOKENSTORAGE)
    Cookies.remove(TOKENSTORAGE)
}