<template>
    <div class="box">
        <LineTitle title="用户实时行为" />
        <table class="tableBox">
            <thead class="tableHead">
                <tr>
                    <th v-for="item in columns" :key="item.dataIndex">{{ item.title }}</th>
                </tr>
            </thead>
            <tbody class="tableBody">
                <tr v-for="(i, index) in tableData" :key="i" class="animate__animated "
                    :class="{ animate__slideInDown: index != 0, animate__fadeInRight: index == 0 }">
                    <td>{{i.name}}</td>
                    <td>暨南大学</td>
                    <td>操作</td>
                    <td>3</td>
                    <td>{{ moment(i.time).format() }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
import { moment } from "@/utils/utils";
import { ref } from "vue"
import LineTitle from '@/components/LineTitle'
const columns = [
    {
        title: '用户名',
        dataIndex: 'username',
        width: 100,
    },
    {
        title: '学校',
        dataIndex: 'school',
        width: 150,
    },
    {
        title: '班级',
        dataIndex: 'class',
        width: 80,
    },
    {
        title: '触发行为',
        dataIndex: 'act',
        width: 120,
    },
    {
        title: '时间',
        dataIndex: 'time',
        width: 160,
    },
];
const tableData = ref([])
setInterval(() => {
    var arr = ['林婉婷','蔡宜芸','林珮瑜','黄柏仪','周逸珮','夏雅惠','王采珮','林孟霖','林竹水','王怡乐','金佳蓉','韩健毓','李士杰','陈萱珍','苏姿婷','张政霖','李志宏','陈素达','陈虹荣','何美玲']
    var i = Math.floor((Math.random() * arr.length));
    const data = {
        name: arr[i],
        time: +new Date(),
    }
    const list = tableData.value
    list.unshift(data)
    tableData.value = JSON.parse(JSON.stringify(list.slice(0, 10)))
}, 5000)
</script>

<style lang="scss" scoped>
.box {
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;

    .title {
        color: rgba(238, 238, 238, 0.563);
        font-size: 20px;
        text-align: center;
        font-weight: 600;
    }

    .tableBox {
        width: 100%;
        text-align: center;
        border-radius: 4px;
        border-collapse: separate;
        border-spacing: 0;
        padding: 20px 8px 0;
        box-sizing: border-box;
        table-layout: fixed;
        overflow: hidden;

        .tableHead {
            &>tr>th {
                color: #53b3e4;
                line-height: 40px;
                border-top: 1px solid rgba(83, 179, 228, 0.3);
                border-bottom: 1px solid rgba(83, 179, 228, 0.3);
            }
        }

        .tableBody {
            &>tr>td {
                color: #fff;
                line-height: 40px;
                border-bottom: 1px solid rgba(83, 179, 228, 0.3);

                &:last-child {
                    width: 150px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

}
</style>