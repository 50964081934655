<template>
  <div class="header_box">
    <div class="time">{{ time }}</div>
    <div class="title">BI平台实时数据看板</div>
    <div class="desc">统计维度：昨天</div>
  </div>
</template>

<script setup>
import { ref, reactive, onUnmounted } from "vue";
import { moment } from "@/utils/utils";

const config = reactive({
  FORMAT: "YYYY/MM/DD HH:mm:SS",
});
const time = ref(moment(new Date()).format(config.FORMAT));
const t = setInterval(() => {
  time.value = moment(new Date()).format(config.FORMAT);
}, 1000);

onUnmounted(() => {
  clearInterval(t);
});
</script>

<style lang="scss" scoped>
.header_box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0;
  color: azure;
  box-sizing: border-box;
  .time {
    font-size: 24px;
  }
  .title {
    font-size: 34px;
    font-weight: 600;
  }
  .desc {
    font-size: 24px;
  }
}
</style>
