import api from '@/utils/request'
const time = {
    start: "2022-1-2",
    end: "2022-8-3"
}
const setting = {
    grant_type: "client_credential",
    appid: "system",
    secret: '87135AB0160F706D8B47F06BDABA6FC6'
}
// 获取token
export const getTokenApi = () => {
    return api({
        url: '/api/Token',
        method: 'get',
        params: {
            website: window.location.hostname,
            grant_type: setting.grant_type,
            appid: setting.appid,
            secret: setting.secret
        }
    })
}
// 汇总
export const GetUserStatisticByType = () => {
    return api({
        url: '/api/Summary/GetUserStatisticByType',
        method: 'get'
    })
}
// 实验排行
export const GetProjectRanking = () => {
    return api({
        url: '/api/Summary/GetProjectRanking',
        method: 'post',
        data: {
            "CurrenetPageIndex": 1,
            "PageSize": 10,
            "Order": "desc",
            "Sort": "PersonTime"
        }
    })
}
// 访问统计
export const GetUserStatisticByTime = () => {
    return api({
        url: '/api/Summary/GetUserStatisticByTime',
        method: 'post',
        data: {
            "end": "2022-8-3",
            "start": "2022-7-2"
        }
    })
}
// 实验分类
export const GetProjectSummaryBySubject = () => {
    return api({
        url: '/api/Summary/GetProjectSummaryBySubject',
        method: 'get'
    })
}
// 院系统计
export const GetProjectSummaryByFullName = () => {
    return api({
        url: '/api/Summary/GetProjectSummaryByFullName',
        method: 'get'
    })
}
// 级别
export const GetProjectSummaryByField = () => {
    return api({
        url: '/api/Summary/GetProjectSummaryByField',
        method: 'get'
    })
}
// 达标比例
export const GetScoreSummary = () => {
    return api({
        url: '/api/Summary/GetScoreSummary',
        method: 'post',
        data: {
            "end": time.end,
            "start": time.start
        }
    })
}
// 实验分析
export const GetTrendDataByMonth = () => {
    return api({
        url: '/api/Summary/GetTrendDataByMonth',
        method: 'post',
        data: {
            "end": time.end,
            "start": time.start
        }
    })
}
// 数据汇总
export const GetProjectSummary = () => {
    return api({
        url: '/api/Summary/GetProjectSummary',
        method: 'get'
    })
}