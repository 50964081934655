import * as echarts from "echarts";

const styles = 'display:inline-block;width:8px;height:8px;border-radius:4px;margin-right:6px;';
// 获取统计
export function getStatisticsChart(data) {
  // 颜色
  const colorList = [
    '#5085f2',
    '#f87be2',
    '#f2719a',
    '#ef6567',
    '#fca4bb',
    '#8d7fec'
  ]
  // 总
  const textTotal = data.reduce((pre, cur) => {
    return pre + cur.Value
  }, 0)
  const elseData = data.slice(4).reduce((previous, current) => {
    let Value = previous.Value + current.Value
    return {
      Value: Value,
      Label: "其他"
    }
  }, {
    Value: 0,
    Label: "其他"
  })
  let newData = data.slice(0, 4)
  newData.push(elseData)
  const chartData = newData.map(i => {
    return {
      value: i.Value,
      name: i.Label
    }
  })
  return {
    title: {
      text: textTotal,
      subtext: '分类数',
      left: 'center',
      top: '30%',
      padding: [10, 0],
      textStyle: {
        color: '#ffc72b',
        fontSize: 26,
        align: 'center',
      },
      subtextStyle: {
        color: '#fff',
        fontSize: 12,
        align: 'center',
      },
    },
    tooltip: {
      formatter: params => {
        return `
          <p style="text-align:left;line-height:18px">
            <i class="chart-circle" style="${styles}background-color:${params.color}"></i>
            ${params.name}
          </p>
          <p style="text-align:left;line-height:18px">
            数量：${params.value}
          </p>
          <p style="text-align:left;line-height:18px">
            占比：${params.percent}%
          </p>
        `;
      },
    },
    legend: {
      bottom: 0,
      textStyle: {
        color: '#fff',
      },
    },
    series: [{
      name: '标题',
      type: 'pie',
      center: ['50%', '40%'],
      radius: ['40%', '60%'],
      minAngle: 10,
      // clockwise: false, //饼图的扇区是否是顺时针排布
      avoidLabelOverlap: false,
      label: {
        normal: {
          show: true,
          position: 'outter',
          formatter(parms) {
            return parms.data.legendname;
          },
        },
      },
      labelLine: {
        normal: {
          length: 5,
          length2: 8,
          smooth: true,
        },
      },
      itemStyle: {
        normal: {
          color: function (colors) {
            return colorList[colors.dataIndex]
          }
        }
      },
      data: chartData
    }, ],
  };
}
// 访问统计
export function getVisitFunnelChart(data) {
  let sum = data.reduce((pre, cur) => pre + cur.value, 0);
  const sortData = data.sort((a, b) => a.value - b.value)
  // 富文本配置
  const rich = {
    yellow: {
      color: '#ffc72b',
      fontSize: 18,
    },
    white: {
      color: '#fff',
      fontSize: 12,
    },
  };
  return {
    calculable: true,
    color: ['#8874a5', '#64609b', '#465192'],
    series: [{
      name: '用户转化',
      type: 'funnel',
      left: 'center',
      top: '10%',
      bottom: '5%',
      width: '70%',
      minSize: '50%',
      gap: 14,
      label: {
        normal: {
          show: true,
          position: 'center',
          formatter: params => {
            let percent = 0;
            data.forEach(value => {
              if (params.name === value.name) {
                percent = (value.value / sum).toFixed(2) * 100;
              }
            });
            return `{yellow| ${percent}}{white|%}\n{circle|}{white| ${params.name} }`;
          },
          rich,
        },
      },
      itemStyle: {
        normal: {
          borderColor: '#fff',
          borderWidth: 0,
        },
      },
      data: [{
          value: 20,
          name: sortData[0].name,
        },
        {
          value: 40,
          name: sortData[1].name,
        },
        {
          value: 60,
          name: sortData[2].name,
        },
      ],
    }, ],
  };
}
export function getVisitLineChart(data) {
  const xAxisData = data.map(i => i.time)
  const schoolData = data.map(i => i.school)
  const platformData = data.map(i => i.platform)
  const ilabData = data.map(i => i.ilab)
  return {
    color: ['#8874a5', '#64609b', '#465192'],
    grid: {
      top: '20px',
      left: '0',
      right: '0',
      bottom: '0',
      containLabel: true,
    },
    xAxis: {
      data: xAxisData,
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          color: 'rgba(168, 178, 185, .1)',
        },
      },
    },
    series: [{
        name: "校外",
        type: 'line',
        data: schoolData,
        smooth: true,
      },
      {
        name: "ilab用户",
        type: 'line',
        data: platformData,
        smooth: true,
      },
      {
        name: "平台用户",
        type: 'line',
        data: ilabData,
        smooth: true,
      },
    ]
  };
}
// 获取中间地图
export function getChinaMap(data) {
  return {
    tooltip: {
      trigger: "item"
    },
    legend: {
      orient: "horizontal", //图例的排列方向
      textStyle: {
        color: "#ccc"
      },
      x: "left", //图例的位置
      y: "-20000000000000",

    },

    visualMap: {

      textStyle: {
        color: "#ccc"
      },
      x: "right",
      y: "bottom",
      top: "70%",
      // bottom: 50,
      show: true,
      color: ['#957fb5', '#4d8ece', '#146b76', 'rgb(50,88,124)'],
      // min: 0,
      // max: 100,
    },
    roamController: { //控制地图的上下左右放大缩小
      show: true,
      x: 'right',
      mapTypeControl: {
        'china': true
      }
    },
    series: [{
      name: "全国数据",
      type: "map",
      mapType: "china",
      zoom: 1.2, //地图大小
      roam: false, //是否开启鼠标缩放和平移漫游
      itemStyle: {
        //地图区域的多边形 图形样式
        normal: {
          //是图形在默认状态下的样式
          label: {
            show: true,
            textStyle: {
              color: "rgb(249, 249, 249)"
            } //地图text样式
          }
        },
        emphasis: {
          //是图形在高亮状态下的样式,比如在鼠标悬浮或者图例联动高亮时
          label: {
            show: true
          }
        }
      },
      top: "70", //组件距离容器的距离
      data: data
    }]
  }
}
// 汇总
export function getPeopleTotalChart(data) {
  const yCategory = [];
  const series = [];
  const sum = data.reduce((pre, cur) => pre + Number(cur.Num), 0);
  data.forEach((item, i) => {
    series.push({
      name: '设置分布',
      type: 'pie',
      clockWise: false, // 顺时加载
      hoverAnimation: false, // 鼠标移入变大
      radius: [`${75 - i * 15}%`, `${66 - i * 15}%`],
      center: ['35%', '55%'],
      label: {
        show: false,
      },
      itemStyle: {
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        borderWidth: 5,
      },
      data: [{
          value: item.Num,
          name: item.Category,
        },
        {
          value: (sum * 4) / 3 - item.Num,
          name: '',
          itemStyle: {
            color: 'rgba(0,0,0,0)',
            borderWidth: 0,
          },
          tooltip: {
            show: false,
          },
          hoverAnimation: false,
        },
      ],
    });
    series.push({
      name: '',
      type: 'pie',
      silent: true,
      z: 1,
      clockWise: false, // 顺时加载
      hoverAnimation: false, // 鼠标移入变大
      radius: [`${75 - i * 15}%`, `${66 - i * 15}%`],
      center: ['35%', '55%'],
      label: {
        show: false,
      },
      itemStyle: {
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
        borderWidth: 5,
      },
      data: [{
          value: 7.5,
          itemStyle: {
            color: 'rgba(151, 136, 136, .4)',
            borderWidth: 0,
          },
          tooltip: {
            show: false,
          },
          hoverAnimation: false,
        },
        {
          value: 2.5,
          name: '',
          itemStyle: {
            color: 'rgba(0,0,0,0)',
            borderWidth: 0,
          },
          tooltip: {
            show: false,
          },
          hoverAnimation: false,
        },
      ],
    });
    yCategory.push(`${((item.Num / sum) * 100).toFixed(2)}%`);
  });
  return {
    color: ['#FF8700', '#ffc300', '#00e473', '#009DFF'],
    tooltip: {
      formatter: params => {
        return `
          <p style="text-align:left;line-height:18px">
            <i class="chart-circle" style="${styles}background-color:${params.color}"></i>
            ${params.name}
          </p>
          <p style="text-align:left;line-height:18px">
            数量：${params.data.value}
          </p>
          <p style="text-align:left;line-height:18px">
            占比：${params.percent}%
          </p>
        `;
      },
    },
    grid: {
      top: '15%',
      bottom: '53%',
      left: '35%',
      containLabel: false,
    },
    legend: {
      show: true,
      top: '20%',
      left: '65%',
      data: data.map(k => k.Category),
      itemWidth: 22,
      itemHeight: 12,
      itemGap: 10,
      formatter: name => {
        const item = data.find(k => k.Category === name);
        return `{title| ${name}}\n{value| ${item.Num} 人}`;
      },
      textStyle: {
        rich: {
          title: {
            fontSize: 10,
            lineHeight: 10,
            color: '#ccc',
          },
          value: {
            fontSize: 14,
            lineHeight: 18,
            color: '#fff',
          },
        },
      },
    },
    yAxis: [{
      type: 'category',
      inverse: true,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        interval: 0,
        inside: true,
        textStyle: {
          color: '#eee',
          fontSize: 10,
        },
        show: true,
      },
      data: yCategory,
    }, ],
    xAxis: [{
      show: false,
    }, ],
    series,
  };
}

// RightTop
export function getAnalysisChart(data) {
  const xAxis = data.map(i => i.Time)
  const numData = data.map(i => i.Number)
  const porjectNumData = data.map(i => i.TotalBrowse)
  return {
    title: {
      text: '实验',
      textStyle: {
        color: '#eee',
        fontSize: 14,
      },
      top: 0,
      left: 'center',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: params => {},
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#666',
        },
      },
      type: 'category',
      boundaryGap: true,
      data: xAxis,
    },
    yAxis: {
      inverse: false,
      splitNumber: 5,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#666',
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(168, 178, 185, .1)',
        },
      },
      type: 'value',
    },
    legend: {
      show: true,
      right: '4%',
      textStyle: {
        color: "#ccc"
      },
    },
    grid: {
      top: '50px',
      left: '0',
      right: '0',
      bottom: '20px',
      containLabel: true,
    },
    series: [{
        name: '实验人数',
        type: 'bar',
        barWidth: '12px',
        stack: '1',
        data: numData,
        label: {
          show: true,
          position: 'top',
          textStyle: {
            color: '#fff',
          },
        },
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#8bd46e',
              },
              {
                offset: 1,
                color: '#09bcb7',
              },
            ]),
            barBorderRadius: 10,
            borderType: 'dashed',
            opacity: "0.7"
          }
        }
      },
      {
        name: '实验浏览数',
        type: 'bar',
        barWidth: '12px',
        stack: '2',
        data: porjectNumData,
        label: {
          show: true,
          position: 'top',
          textStyle: {
            color: '#fff',
          },
        },
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#248ff7',
              },
              {
                offset: 1,
                color: '#6851f1',
              },
            ]),
            barBorderRadius: 10,
            borderType: 'dashed',
            opacity: "0.7"
          }
        }
      },
    ]
  };
}

export function getBottomLeftChart(data) {
  const xAxis = data.map(i => i.Label + '年')
  const nationalData = data.filter(i => i.Describe === '国家级')
  const provinciallData = data.filter(i => i.Describe === '省级')
  const schoolData = data.filter(i => i.Describe === '校级')
  const list = [{
    name: "国家级",
    data: nationalData.map(i => i.Value),
    color: ['#28ffb3']
  }, {
    name: "省级",
    data: provinciallData.map(i => i.Value),
    color: ['#ff87f8']
  }, {
    name: "校级",
    data: schoolData.map(i => i.Value),
    color: ['#5892ff']
  }]
  return {
    title: {
      text: '校级项目',
      textStyle: {
        color: '#eee',
        fontSize: 14,
      },
      top: 0,
      left: 'center',
    },
    grid: {
      top: '50px',
      left: '0',
      right: '0',
      bottom: '0',
      containLabel: true,
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#666',
        },
      },
      type: 'category',
      boundaryGap: true,
      data: xAxis,
    },
    yAxis: {
      inverse: false,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        textStyle: {
          fontSize: 10,
          color: '#666',
        },
      },
      splitLine: {
        lineStyle: {
          color: 'rgba(168, 178, 185, .1)',
        },
      },
      type: 'value',
    },
    legend: {
      show: true,
      right: '4%',
      // icon: 'rect',
      itemWidth: 14,
      itemHeight: 5,
      itemGap: 13,
      top: 25,
      textStyle: {
        color: "#ccc"
      },
    },
    series: list.map(i => {
      return {
        name: i.name,
        type: 'line',
        // smooth: true, //是否平滑曲线显示
        showAllSymbol: true,
        symbol: 'emptyCircle',
        symbolSize: 6,
        lineStyle: {
          normal: {
            color: i.color[0], // 线条颜色
          },
          borderColor: '#f0f',
        },
        label: {
          show: true,
          position: 'top',
          textStyle: {
            color: '#fff',
          },
        },
        itemStyle: {
          normal: {
            color: i.color[0],
          },
        },
        tooltip: {
          show: false,
        },
        areaStyle: {
          // 区域填充样式
          normal: {
            // 线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(0,154,120,1)',
                },
                {
                  offset: 1,
                  color: 'rgba(0,0,0, 0)',
                },
              ],
              false
            ),
            shadowColor: 'rgba(53,142,215, 0.9)', // 阴影颜色
            shadowBlur: 20, // shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
          },
        },
        data: i.data,
      }
    })
  };
}
export function getBottomRightChart(data) {
  const xAxis = data.map(i => i.Time)
  const passData = data.map(i => i.Pass)
  const failsData = data.map(i => i.Fail)
  const goodData = data.map(i => i.Excellent)
  const list = [{
      name: "达标",
      data: passData,
      color: ['#5597e6']
    },
    {
      name: "优秀",
      data: goodData,
      color: ['#77fbe3']
    },
    {
      name: "不达标",
      data: failsData,
      color: ['#f2719a']
    }
  ]
  return {
    title: {
      text: '比例',
      textStyle: {
        color: '#eee',
        fontSize: 14,
      },
      top: 0,
      left: 'center',
    },
    tooltip: {
      formatter: params => {},
    },
    grid: {
      top: '50px',
      left: '20px',
      right: '0',
      bottom: '0',
      containLabel: true,
    },
    xAxis: {
      data: xAxis,
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          color: 'rgba(168, 178, 185, .1)',
        },
      },
    },
    legend: {
      icon: 'rect',
      itemWidth: 14,
      itemHeight: 5,
      itemGap: 13,
      data: list.map(i => i.name),
      right: '4%',
      top: 25,
      textStyle: {
        fontSize: 12,
        color: '#ccc',
      },
    },
    series: list.map(i => {
      return {
        name: i.name,
        showSymbol: false,
        data: i.data,
        type: 'line',
        smooth: true,
        areaStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
              [{
                  offset: 0,
                  color: 'rgba(16,97,204, 0.4)',
                },
                {
                  offset: 0.8,
                  color: 'rgba(17,235,210, 0)',
                },
              ], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10,
            lineStyle: {
              width: 1.5,
            },
          },
        },
        itemStyle: {
          normal: {
            color: i.color[0],
          },
        },
      }
    })
  };
}