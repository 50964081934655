<template>
  <div class="app">
    <router-view />
  </div>
</template>
<style lang="scss">
.app {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}
</style>
